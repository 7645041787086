@import url(https://fonts.googleapis.com/css?family=Antic+Slab);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300);
@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300);

.App {
  text-align: center;
  font-family: 'Roboto', serif;
}


body {
  background-color: #3EA6D7 !important;
}

.wrapper {
  min-height:100vh;
}

.h1-title
{
  font-family: 'Roboto', serif;
  padding-bottom: 10px;
  font-size: 3.5em !important;
  font-weight: 100;


}

.h1-title.mobile
{
  font-family: 'Roboto', serif;
  margin : 0;
  padding-bottom: 10px;
  font-size: 3em !important;
  font-weight: 100;
}

.h2-title
{
  font-family: 'Roboto', serif;
  padding-bottom: 10px;
  font-size: 2.5em !important;
  font-weight: 100;


}

.h2-title.mobile
{
  font-family: 'Roboto', serif;
  margin : 0;
  padding-bottom: 10px;
  font-size: 2em !important;
  font-weight: 100;
}

.footer {
  text-align: center;
  padding: 30px 0;
  margin-top: 70px;
  border-top: 1px solid #e5e5e5;
  background-color: #f5f5f5;
}

.h3-title
{
  /*font-family: 'Open Sans', sans-serif; */
  font-family: 'Open Sans Condensed', sans-serif;

}

.product
{
  min-height: 500px;
}

.h-blue
{
  color:#0c41ff;
}

.h-red
{
  color: #f30;
}

.h-orange
{
  color: #ff9515;
}

.h-green
{
  color: #00852e;
}

@media (max-width: 480px) {
  .hero-unit
  {
      padding : 30px !important;
  }
}

.margin15 {
  margin-top: 15%;
}

.margin-15 {
  margin-bottom: 15%;
}

.margin10 {
  margin-top: 10%;
}

.margin-10 {
  margin-bottom: 10%;
}

.margin5 {
  margin-top: 5%;
}

.margin-5 {
  margin-bottom: 5%;
}

.h-blue
{
  color:#0c41ff;
}

.h-red
{
  color: #f30;
}

.h-orange
{
  color: #ff9515;
}

.h-green
{
  color: #00852e;
}

.h-plum
{
  color: #660066;
}

@media (max-width: 480px) {
  .hero-unit
  {
      padding : 30px !important;
  }
}

.footer p {
  margin-bottom: 0;
  color: #777;
}